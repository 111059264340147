import Img from "components/Image"
import Text from "components/Typography/Text"

import "./ChatEmpty.styl"

const ChatEmpty = () => (
  <section className="chat-empty">
    <Img
      className="chat-empty__image"
      file="empty-chat"
      width="222"
      height="205"
    />
    <div className="chat-empty__text">
      <Text className="chat-empty__title">
        Nenhuma conversa ativa no momento
      </Text>
      <Text className="chat-empty__subtitle">
        Assim que algum cliente responder,
        <br /> as conversas aparecerão aqui.
      </Text>
    </div>
  </section>
)

export default ChatEmpty
