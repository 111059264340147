import "./Chips.styl"

const Chips = ({
  label,
  id,
  type = "checkbox",
  name,
  form: { register },
  defaultValue,
  ...props
}) => {
  return (
    <div className="form-chips">
      <input
        className="form-chips__input"
        id={id}
        type={type}
        defaultValue={defaultValue}
        {...register(name)}
        {...props}
      />
      <label className="form-chips__label" htmlFor={id}>
        <span>{label}</span>
      </label>
    </div>
  )
}

export default Chips
