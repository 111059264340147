import { Mobile, Desktop } from "components/Devices"
import DesktopScreen from "./Desktop"
import MobileScreen from "./Mobile"

const PotentialCustomers = ({ pageContext }) => {
  return (
    <>
      <Desktop>
        <DesktopScreen pageContext={pageContext} />
      </Desktop>
      <Mobile>
        <MobileScreen pageContext={pageContext} />
      </Mobile>
    </>
  )
}

export default PotentialCustomers
