import { useEffect } from "react"
import Form from "components/Forms/Form"
import Button from "components/Button"
import Input from "components/Forms/Input"
import If from "components/If"
import Title from "components/Typography/Title"
import Icon from "components/Icon"

import { isInputText } from "./utils"
import useFilter from "./hooks"
import classnames from "classnames"

import "./Filter.styl"

const Filter = ({ filters, setFilters, className, onClose, business }) => {
  const { resetRemovedFields, filtersFields, onSubmit } = useFilter({
    business,
    setFilters,
    filters,
  })

  return (
    <div className={classnames("filter", className)}>
      <Form
        manually={true}
        onSubmit={onSubmit}
        className="filter__form"
        defaultValues={filters}
      >
        {({ form, resetField }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            resetRemovedFields({ filters, resetField })
          }, [resetField])

          return (
            <>
              <Title className="filter__title" variant="small">
                Filtros
                <button className="filter__close-btn" onClick={onClose}>
                  <Icon name="close" />
                </button>
              </Title>

              <ul className="filter__list">
                {filtersFields?.map((filter) => (
                  <li className="filter__item" key={filter.query_string}>
                    <If condition={isInputText(filter)}>
                      <span className="filter__label">{filter.label}</span>
                      <Input name={filter.query_string} form={form} />
                    </If>
                  </li>
                ))}
              </ul>
              <If condition={filtersFields.length}>
                <div className="filter__submit">
                  <Button
                    className="filter__button-submit"
                    type="submit"
                    onClick={onClose}
                  >
                    Filtrar
                  </Button>
                </div>
              </If>
            </>
          )
        }}
      </Form>
    </div>
  )
}

export default Filter
