import classnames from "classnames"

import "./Panel.styl"

const Panel = ({ className, children, onClose, isActive }) => {
  return (
    <div
      data-test="panel"
      className={classnames("panel", {
        "panel--active": isActive,
        [className]: className,
      })}
    >
      <div
        data-test="panel-overlay"
        className="panel__overlay"
        onClick={onClose}
      />
      <aside className="panel__content">{children}</aside>
    </div>
  )
}

export default Panel
