import { usePotentialCustomerContext } from "../../../../context"

const useKanbanTabs = () => {
  const {
    context: { page },
    business,
    updateContext,
  } = usePotentialCustomerContext()

  const changeTab = (tab) => {
    updateContext({ filters: {}, search: "", page: tab })
  }

  return {
    tab: page,
    changeTab,
    business,
  }
}

export default useKanbanTabs
