import { useState, useEffect, useCallback } from "react"
import { usePotentialCustomerContext } from "../../../../context"
import debounce from "utils/debounce"
import { useParameter } from "hooks/useParameters"
import { Tabs } from "../../../../constants"
import helpers from "utils/helpers"
import scroll from "utils/scroll"
import services from "services"
import auth from "utils/auth"
import network from "utils/network"

const useHeader = ({ business_id }) => {
  const user = auth.getUser()
  const [userUUID] = useParameter("user_uuid")
  const [isFilter, setIsFilter] = useState(false)
  const [leadCounter, setLeadCounter] = useState(null)
  const [isBucketAuthorizing, setIsBucketAuthorizing] = useState(false)
  const [pageHeader, setPageHeader] = useState({ title: "Clientes Potenciais" })
  const {
    context: { page, search, filters, status, whatsapp },
    updateContext,
  } = usePotentialCustomerContext()

  const openRedistribution = () => {
    updateContext({
      redistribution: { active: true },
    })
  }

  const handleLeadFromQueue = useCallback(({ code, error }) => {
    const hasErrorPopUp = error || code
    setIsBucketAuthorizing(!hasErrorPopUp)
  }, [])

  const getLeadFromQueue = useCallback(() => {
    services.whatsapp
      .getLeadFromQueue({ business_id, group_id: user?.group_id })
      .then(handleLeadFromQueue)
  }, [business_id, handleLeadFromQueue, user.group_id])

  const getActiveLeadsCounter = useCallback(async () => {
    const counter = await services.whatsapp.leadsCounter({
      business_id,
      group_id: user?.group_id,
    })
    setLeadCounter(counter)
  }, [business_id, user.group_id])

  const onConfirmBucket = () => {
    updateContext({
      whatsapp: { shouldReloadList: true },
    })
    setIsBucketAuthorizing(false)
    getActiveLeadsCounter()
  }

  const onSearch = debounce((target) => {
    const value = helpers.isSearchable(target.value) ? target.value : ""

    if (helpers.isSearchable(target.value)) {
      updateContext({
        search: value,
        page: Tabs.Search,
      })
    }
  }, 1000)

  const onFilter = (target) => {
    const statusId = target.value
    const context = !statusId
      ? { filters: {}, page: Tabs.Kanban }
      : {
          filters: {
            ...filters,
            status_ids: [String(statusId)],
          },
          page: Tabs.Search,
        }

    updateContext(context)
  }

  const onToggleFilter = () => {
    isFilter ? scroll.body.enable() : scroll.body.disabled()
    setIsFilter(!isFilter)
  }

  const handleFetch = useCallback(({ user_name }) => {
    setPageHeader({
      title: user_name,
      subtitle: "Clientes Potenciais",
      reverse: true,
    })
  }, [])

  const fetchUser = useCallback(() => {
    if (userUUID) {
      services.user.get({ user_id: userUUID }).then(handleFetch)
    }
  }, [handleFetch, userUUID])

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  useEffect(() => {
    if (page === Tabs.WhatsApp) {
      const handle = network.setPolling({
        fn: getActiveLeadsCounter,
        ms: 4000,
      })

      return () => {
        clearInterval(handle)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page?.description])

  return {
    status,
    isFilter,
    onSearch,
    onFilter,
    pageHeader,
    leadCounter,
    onToggleFilter,
    onConfirmBucket,
    getLeadFromQueue,
    openRedistribution,
    isBucketAuthorizing,
    initialValues: { search },
    isSearch: page === Tabs.Search,
    isWhatsApp: page === Tabs.WhatsApp,
  }
}

export default useHeader
