export const Tabs = {
  Kanban: Symbol("kanban"),
  Search: Symbol("search"),
  WhatsApp: Symbol("whatsapp"),
}

export const TabsName = {
  [Tabs.Kanban]: "Kanban",
  [Tabs.Search]: "Search",
  [Tabs.WhatsApp]: "WhatsApp",
}
