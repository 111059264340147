import { useState, useCallback, useEffect, useRef } from "react"
import { useParameter } from "hooks/useParameters"
import services from "services"
import scroll from "utils/scroll"
import helpers from "utils/helpers"
import debounce from "utils/debounce"
import isClient from "utils/isClient"

const usePotentialCustomers = ({ business }) => {
  const isMountedRef = useRef(null)
  const [leads, setLeads] = useState([])
  const [status, setStatus] = useState([])
  const [filters, setFilters] = useState({})
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false)
  const [selectedFilterId, setSelectedFilterId] = useState("1")
  const [pagination, setPagination] = useState({
    quantity: 10,
    total_records: 10,
  })
  const [modal, setModal] = useState({
    lead_uuid: null,
    active: false,
  })
  const [userUUID] = useParameter("user_uuid")

  const onToggleFilterSidebar = () => {
    isFilterSidebarOpen ? scroll.body.enable() : scroll.body.disabled()
    setIsFilterSidebarOpen(!isFilterSidebarOpen)
  }

  const onCloseModal = () => {
    setModal({
      active: false,
    })
    fetchLeads()
  }

  const openModal = ({ lead_uuid }) => {
    setModal({
      lead_uuid,
      active: true,
    })
  }

  const handleFetch = useCallback(({ leads: { kanban = [] }, status }) => {
    const [result] = kanban
    const [statuses] = status

    if (isMountedRef.current) {
      setLeads(result?.paginated_result?.data)
      setStatus(statuses.values)
      setPagination((prevState) => ({
        quantity: prevState.quantity,
        total_records:
          result?.paginated_result?.pagination?.total_records || 10,
      }))
    }
  }, [])

  const fetchLeads = useCallback(
    async (props = {}) => {
      const { search = "" } = props
      const filtersPayload = !search
        ? {
            [`status_ids`]: selectedFilterId,
            ...filters,
          }
        : {}

      const leads = await services.bases.leads({
        hideLoader: false,
        business_id: business.business_id,
        user_uuid: userUUID,
        filters: filtersPayload,
        pagination: {
          quantity: pagination.quantity,
        },
        ...props,
      })

      const status = await services.bases.filters({
        hideLoader: false,
        business_id: business.business_id,
        ...props,
      })

      handleFetch({ leads, status })
    },
    [
      filters,
      userUUID,
      handleFetch,
      selectedFilterId,
      pagination.quantity,
      business.business_id,
    ]
  )

  const onSearch = debounce((target) => {
    const value = helpers.isSearchable(target.value) ? target.value : ""
    fetchLeads({ search: value, hideLoader: true })
  }, 1000)

  const onFilterByStatus = (statusId) => {
    setSelectedFilterId(String(statusId))
    setPagination({ quantity: 10 })
  }

  const handleInfiniteScroll = useCallback(() => {
    const isWindowAtBottom =
      window.innerHeight + window.scrollY >= document.body.offsetHeight

    if (isWindowAtBottom) {
      setPagination((prevState) => ({
        ...prevState,
        quantity: prevState.quantity + 10,
      }))
    }
  }, [])

  useEffect(() => {
    isMountedRef.current = true

    fetchLeads()

    return () => (isMountedRef.current = false)
  }, [fetchLeads, filters])

  useEffect(() => {
    if (isClient()) {
      window.addEventListener("scroll", debounce(handleInfiniteScroll, 500))
      return () => window.removeEventListener("scroll")
    }
  }, [fetchLeads, handleInfiniteScroll])

  return {
    leads,
    status,
    selectedFilterId,
    modal: {
      ...modal,
      openModal,
      onCloseModal,
    },
    onSearch,
    onFilterByStatus,
    filterProps: {
      filters,
      setFilters,
      isFilterSidebarOpen,
      onToggleFilterSidebar,
    },
  }
}

export default usePotentialCustomers
