export const setPolling = ({
  fn = () => true,
  cb = (arg) => arg,
  ms = 1000,
}) => {
  cb(fn())

  setInterval(() => {
    const result = fn()

    cb(result)
  }, ms)
}

export default {
  setPolling,
}
