import BadgeStatus from "components/BadgeStatus"
import If from "components/If"
import Text from "components/Typography/Text"
import classnames from "classnames"

import "./ChatCard.styl"

const ChatCard = ({
  status = "Indefinido",
  statusColor,
  name,
  phone,
  email,
  hour,
  unread,
  active,
  onClick,
  ...props
}) => {
  return (
    <div
      className={classnames("chat-card", {
        "chat-card--active": active,
        "chat-card--unread": !active && unread,
      })}
      role="presentation"
      aria-label={`Lead: ${name}`}
      onClick={onClick}
      {...props}
    >
      <div className="chat-card__header">
        <BadgeStatus className="chat-card__status" statusColor={statusColor}>
          {status}
        </BadgeStatus>
        <If condition={hour}>
          <Text className="chat-card__hour" variant="small">
            {hour}
          </Text>
        </If>
      </div>
      <Text className="chat-card__name">{name}</Text>
      <div className="chat-card__content">
        <If condition={phone}>
          <Text className="chat-card__phone" variant="small">
            {phone}
          </Text>
        </If>
        <If condition={email}>
          <Text className="chat-card__email" variant="small">
            {email}
          </Text>
        </If>
      </div>
    </div>
  )
}

export default ChatCard
