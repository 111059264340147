import Form from "components/Forms/Form"
import Button from "components/Button"
import Chips from "components/Forms/Fields/Chips"
import Input from "components/Forms/Input"
import If from "components/If"
import Title from "components/Typography/Title"
import { Desktop, Mobile } from "components/Devices"

import { isCheckbox, isDate, isInputText } from "./utils"
import useFilter from "./hooks"
import classnames from "classnames"

import "./Filter.styl"
import DateTime from "components/Forms/DateTime"

const Filter = ({ className, onClose, business }) => {
  const { filters, onSubmit, initialValues } = useFilter({ business })

  return (
    <div className={classnames("filter", className)}>
      <Form
        manually={true}
        onSubmit={onSubmit}
        className="filter__form"
        defaultValues={initialValues}
      >
        {({ form }) => (
          <>
            <Title className="filter__title" variant="small">
              Filtros
            </Title>
            <ul className="filter__list">
              {filters?.map((filter) => (
                <li className="filter__item" key={filter.query_string}>
                  <If condition={isInputText(filter)}>
                    <span className="filter__label">{filter.label}</span>
                    <Input name={filter.query_string} form={form} />
                  </If>
                  <Desktop>
                    <If condition={isDate(filter)}>
                      <span className="filter__label">{filter.label}</span>
                      <div className="filter__control">
                        <If
                          condition={["date", "datetime"].includes(
                            filter.filter_type
                          )}
                          renderIf={
                            <DateTime name={filter.query_string} form={form} />
                          }
                          renderElse={
                            <Input
                              type={filter.filter_type}
                              name={filter.query_string}
                              form={form}
                            />
                          }
                        />
                      </div>
                    </If>
                  </Desktop>
                  <Desktop>
                    <If condition={isCheckbox(filter)}>
                      <span className="filter__label">{filter.label}</span>
                      <ul
                        className="filter__options"
                        role="group"
                        aria-labelledby="checkbox-group"
                      >
                        {Array.isArray(filter.values) &&
                          filter.values.map((field) => (
                            <li className="filter__option" key={field.id}>
                              <Chips
                                data-test="chips-status-id"
                                id={`${filter.query_string}_${field.id}`}
                                label={field.value}
                                name={filter.query_string}
                                defaultValue={String(field.id)}
                                form={form}
                              />
                            </li>
                          ))}
                      </ul>
                    </If>
                  </Desktop>
                </li>
              ))}
            </ul>
            <If condition={filters.length}>
              <div className="filter__submit">
                <Button
                  className="filter__button-submit"
                  type="submit"
                  onClick={onClose}
                >
                  Filtrar
                </Button>
              </div>
            </If>
          </>
        )}
      </Form>
    </div>
  )
}

export default Filter
