import { useEffect, useRef } from "react"
import auth from "utils/auth"
import useMessage from "hooks/useMessage"

const useChatWindow = ({ ticketId, statusName }) => {
  const iframe = useRef(null)
  const isConnected = useRef(false)
  const { email } = auth.getUser()

  useMessage("isConnected", (send) => {
    console.info("Recebido - Evento isConnected")

    send({ type: "onlyChat" })
    console.info("Enviando - Evento onlyChat")

    send({ type: "openTicket", id: ticketId })
    console.info("Enviando - Evento openTicket")

    isConnected.current = true

    console.info("Enviando os dados -", { type: "openTicket", id: ticketId })
  })

  useEffect(() => {
    const shouldMessage = iframe.current && isConnected.current

    if (shouldMessage) {
      console.info(
        "Enviando dados para o iframe",
        iframe.current,
        "com os valores",
        {
          type: "openTicket",
          id: ticketId,
        }
      )
      iframe.current.contentWindow.postMessage(
        {
          type: "openTicket",
          id: ticketId,
        },
        "*"
      )
    }
  }, [ticketId, statusName])

  return {
    email,
    iframe,
  }
}

export default useChatWindow
