const handleFieldType = ({ filter_type, type }) => filter_type === type

export const isCheckbox = ({ filter_type }) =>
  handleFieldType({ filter_type, type: "checkbox" })

export const isDate = ({ filter_type }) =>
  handleFieldType({ filter_type, type: "date" })

export const isInputText = ({ filter_type }) =>
  handleFieldType({ filter_type, type: "input" })
