// import If from "components/If"
import _date from "utils/date"
import _string from "utils/string"
import Icon from "components/Icon"

import "./ListSelectedFilters.styl"

const SelectedFilter = ({ name = "", text = "", updateFilters }) => (
  <li className="potential-customers-selected-filters__item">
    {text}

    <Icon
      name="close-normal"
      onClick={(e) =>
        updateFilters((prevState) => {
          return {
            ...prevState,
            [name]: null,
          }
        })
      }
    />
  </li>
)

const ListSelectedFilters = ({ filters, setFilters }) => {
  return (
    <ul className="potential-customers-selected-filters">
      {Object.entries(filters)
        ?.filter(([key, value]) => !!value)
        ?.map(([key, value]) => {
          return (
            <SelectedFilter
              name={key}
              updateFilters={setFilters}
              text={_string.capitalize(value)}
            />
          )
        })}
    </ul>
  )
}

export default ListSelectedFilters
