import { IFRAME_URL, ORIGIN } from "./constants"

import useChatWindow from "./hooks"

import "./ChatWindow.styl"

const ChatWindow = ({ chat }) => {
  const { email, iframe } = useChatWindow({
    ticketId: chat?.whatsapp_ticket_uuid,
    statusName: chat?.status_name,
  })

  const URL = `${IFRAME_URL}?userid=${encodeURIComponent(
    email
  )}&fromurl=${ORIGIN}`

  return (
    <div className="chat-window">
      <iframe
        src={URL}
        ref={iframe}
        width="100%"
        height="100%"
        frameBorder="0"
        title="Conversa ativa"
      />
    </div>
  )
}

export default ChatWindow
