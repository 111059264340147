import Core from "./components/Core"
import { PotentialCustomerProvider } from "./context"

const PotentialCustomers = ({ pageContext: { business } }) => {
  return (
    <PotentialCustomerProvider business={business}>
      <Core />
    </PotentialCustomerProvider>
  )
}

export default PotentialCustomers
