import PageHeader from "components/PageHeader"
import Form from "components/Forms/Form"
import Input from "components/Forms/Input"
import Button from "components/Button"
import FilterAside from "../Filter/Aside"
import ListSelectedFilters from "../ListSelectedFilters"

import "./Header.styl"

const Header = ({ business, onSearch, filterProps }) => {
  const { filters, setFilters, isFilterSidebarOpen, onToggleFilterSidebar } =
    filterProps

  return (
    <PageHeader className="header-customers" title="Clientes Potenciais">
      <Form className="header-customers__form">
        <Input
          className="header-customers__search"
          name="search"
          placeholder="Buscar por nome, email, CPF ou CNPJ"
          icon="search"
          variant="small"
          onChange={({ target }) => onSearch(target)}
          margin="false"
        />
        <Button
          className="potential-customers-header__filter"
          icon="filter"
          type="button"
          variant="group"
          onClick={onToggleFilterSidebar}
        >
          Filtrar
        </Button>
      </Form>

      <ListSelectedFilters filters={filters} setFilters={setFilters} />

      <FilterAside
        business={business}
        filters={filters}
        setFilters={setFilters}
        isActive={isFilterSidebarOpen}
        onClose={onToggleFilterSidebar}
      />
    </PageHeader>
  )
}

export default Header
