import If from "components/If"
import LeadModal from "components/LeadModal"
import { usePotentialCustomerContext } from "../../context"
import ChatEmpty from "./components/ChatEmpty"
import ChatList from "./components/ChatList"
import ChatWindow from "./components/ChatWindow"

import useWhatsApp from "./hooks"
import "./WhatsApp.styl"

const WhatsApp = () => {
  const { business } = usePotentialCustomerContext()
  const { chats, activeChat, changeChat, loadChat } = useWhatsApp()

  return (
    <If
      condition={chats.length}
      renderIf={
        <main className="whatsapp">
          <ChatList
            activeLeadId={activeChat?.lead_uuid}
            chats={chats}
            changeChat={changeChat}
          />
          <ChatWindow chat={activeChat} />
          <If condition={activeChat?.lead_uuid}>
            <LeadModal
              active={true}
              type="component"
              viewport="mobile"
              leadUUID={activeChat?.lead_uuid}
              business={business}
              onSubmit={loadChat}
            />
          </If>
        </main>
      }
      renderElse={<ChatEmpty />}
    />
  )
}

export default WhatsApp
