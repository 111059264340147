import LeadModal from "components/LeadModal"
import Redistribution from "components/Redistribution"
import KanbanTabs from "./components/KanbanTabs"
import Header from "./components/Header"
import { usePotentialCustomerContext } from "../../context"
import Content from "../Content"

const Core = () => {
  const {
    business,
    context: { modal, redistribution, userUUID },
  } = usePotentialCustomerContext()

  return (
    <>
      <Header business={business} />
      <KanbanTabs />
      <Content />
      <LeadModal
        active={modal?.active}
        leadUUID={modal?.lead_uuid}
        business={business}
        onClose={modal.onCloseModal}
      />
      <Redistribution
        active={redistribution?.active}
        userUUID={userUUID}
        businessId={business?.business_id}
        onClose={redistribution.onCloseRedistribution}
      />
    </>
  )
}

export default Core
