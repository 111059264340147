import If from "components/If"
import Can from "components/Can"
import PopUp from "components/PopUp"
import Button from "components/Button"
import Form from "components/Forms/Form"
import FilterAside from "../Filter/Aside"
import Input from "components/Forms/Input"
import Select from "components/Forms/Select"
import PageHeader from "components/PageHeader"
import { Desktop } from "components/Devices"

import useHeader from "./hooks"

import "./Header.styl"

const Header = ({ business }) => {
  const {
    status,
    isFilter,
    isSearch,
    onSearch,
    onFilter,
    pageHeader,
    isWhatsApp,
    leadCounter,
    initialValues,
    onToggleFilter,
    onConfirmBucket,
    getLeadFromQueue,
    openRedistribution,
    isBucketAuthorizing,
  } = useHeader({ business_id: business.business_id })

  return (
    <PageHeader className="potential-customers-header" {...pageHeader}>
      <div className="potential-customers-header__actions">
        <If condition={isWhatsApp}>
          <Can
            subject={[business.business_permission, "salesman"]}
            and={true}
            do={["whatsapp-management", "it"]}
          >
            <Button
              icon="bucket"
              variant="group"
              data-test="bucket-button"
              onClick={getLeadFromQueue}
              disabled={leadCounter === 0}
              className="potential-customers-header__bucket"
            >
              Clientes na fila
              <span className="potential-customers-header__bucket--counter">
                {leadCounter}
              </span>
            </Button>
          </Can>
        </If>
        <Form
          className="potential-customers-header__form"
          defaultValues={initialValues}
          manually={true}
        >
          {({ form }) => (
            <>
              <Input
                className="potential-customers-header__search"
                name="search"
                placeholder="Buscar por nome, email, CPF ou CNPJ"
                icon="search"
                variant="small"
                onChange={(e) => onSearch(e.target)}
                margin="false"
                form={form}
              />
              <Select
                variant="small"
                name="statuses"
                onChange={(e) => onFilter(e.target)}
                defaultValue=""
                form={form}
              >
                <option value="">Todos os status</option>
                {status?.map(({ status_id, status_name, count }) => (
                  <option value={status_id} key={status_id}>
                    {status_name} ({count})
                  </option>
                ))}
              </Select>
            </>
          )}
        </Form>
        <If condition={!isSearch}>
          <Button
            className="potential-customers-header__filter"
            icon="filter"
            variant="group"
            onClick={onToggleFilter}
          >
            Filtrar
          </Button>
        </If>
        <Desktop>
          <Can business={business.business_permission} do="redistribution">
            <Button
              className="potential-customers-header__redistribution"
              icon="arrows-redistribution"
              variant="group"
              onClick={openRedistribution}
            >
              Redistribuir
            </Button>
          </Can>
        </Desktop>
      </div>
      <If condition={!isSearch}>
        <FilterAside
          business={business}
          isActive={isFilter}
          onClose={onToggleFilter}
        />
      </If>
      <PopUp
        icon="success-outlined"
        data-test="bucket-popup"
        visibility={isBucketAuthorizing}
        className="confirm-bucket-popup"
        title="Nova conversa adicionada"
        button={{
          text: "Continuar",
          onClick: onConfirmBucket,
          className: "confirm-bucket-popup__close",
        }}
      ></PopUp>
    </PageHeader>
  )
}

export default Header
