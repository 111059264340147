import { useState, useCallback, useEffect } from "react"
import services from "services"
import { usePotentialCustomerContext } from "../../../../../context"
import { Tabs } from "../../../../../constants"

const useFilter = ({ business = {} }) => {
  const { context, updateContext } = usePotentialCustomerContext()
  const [filters, setFilters] = useState([])

  const handleFetch = useCallback((filters) => {
    setFilters(filters)
  }, [])

  const fetchFilters = useCallback(() => {
    services.bases
      .filters({ business_id: business?.business_id })
      .then(handleFetch)
  }, [handleFetch, business.business_id])

  const onSubmit = (formData) => {
    updateContext({ filters: formData, page: Tabs.Search })
  }

  useEffect(() => {
    fetchFilters()
  }, [fetchFilters])

  return {
    filters,
    initialValues: context.filters,
    onSubmit,
  }
}

export default useFilter
