import classnames from "classnames"
import Text from "components/Typography/Text"

import "./Swiper.styl"

const Swiper = ({ className, status, activeId, onClick }) => {
  return (
    <div className={classnames("swiper", className)}>
      <ul className="swiper__list">
        {Array.isArray(status) &&
          status.map((item) => (
            <li
              key={item.id}
              className="swiper__item"
              onClick={() => onClick(item.id)}
              aria-label={`Slide: ${item.value}`}
            >
              <Text
                className={classnames("swiper__text", {
                  "swiper__text--active": Number(activeId) === Number(item.id),
                })}
              >
                {item.value}
              </Text>
            </li>
          ))}
      </ul>
    </div>
  )
}

export default Swiper
