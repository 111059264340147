import { Mobile } from "components/Devices"
import EmptyState from "components/EmptyState"
import If from "components/If"
import Header from "./components/Header"
import LeadCard from "components/LeadCard"
import LeadModal from "components/LeadModal"
import Swiper from "components/Swiper"

import usePotentialCustomers from "./hooks"

import "./PotentialCustomers.styl"

const PotentialCustomers = ({ pageContext: { business } }) => {
  const {
    leads,
    status,
    selectedFilterId,
    modal: { active, lead_uuid, openModal, onCloseModal },
    onSearch,
    onFilterByStatus,
    filterProps,
  } = usePotentialCustomers({ business })

  return (
    <Mobile>
      <Header
        business={business}
        onSearch={onSearch}
        filterProps={filterProps}
      />

      <Swiper
        className="potential-customers__swiper"
        status={status}
        activeId={selectedFilterId}
        onClick={onFilterByStatus}
      />

      <div className="potential-customers__content">
        <If
          condition={leads?.length}
          renderIf={
            <ul className="potential-customers__leads">
              {leads?.map((lead) => (
                <li className="potential-customers__lead" key={lead.lead_uuid}>
                  <LeadCard
                    statusColor={lead.status_color}
                    status={lead.lead_status}
                    name={lead.lead_name}
                    phone={lead.phone_number}
                    email={lead.email}
                    createdAt={lead.format_created_at}
                    updatedAt={lead.format_updated_at}
                    onClick={() => openModal({ lead_uuid: lead.lead_uuid })}
                  />
                </li>
              ))}
            </ul>
          }
          renderElse={
            <EmptyState
              className="potential-customers__empty"
              text="Não foram encontrados leads com esse filtro"
            />
          }
        />
      </div>
      <LeadModal
        active={active}
        leadUUID={lead_uuid}
        business={business}
        onClose={onCloseModal}
      />
    </Mobile>
  )
}

export default PotentialCustomers
