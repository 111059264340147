import { useCallback, useEffect, useState } from "react"
import services from "services"
import network from "utils/network"
import { usePotentialCustomerContext } from "../../context"
import { findByLeadUiid } from "./utils"

const useWhatsApp = () => {
  const {
    business: { business_id },
    context: {
      whatsapp: { lead: initialLead, shouldReloadList },
    },
    updateContext,
  } = usePotentialCustomerContext()
  const [activeChat, setActiveChat] = useState({})
  const [chats, setChats] = useState([])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const markAsRead = () => {
    const list = chats
    const selectedId = activeChat?.lead_uuid

    if (selectedId) {
      for (const [index, chat] of list.entries()) {
        if (selectedId === chat?.lead_uuid) {
          list[index] = { ...chat, unread: false }

          break
        }
      }
    }

    setChats(list)
  }

  const changeChat = useCallback((chat, list = null) => {
    if (list) {
      setActiveChat((oldChat) => {
        const shouldMaintainOld = findByLeadUiid({
          leadUiid: oldChat?.lead_uuid,
          list,
        })
        const selected = shouldMaintainOld ? oldChat : chat

        return selected
      })
    } else {
      setActiveChat(chat)
    }
  }, [])

  const handleFetch = useCallback(
    ({ list, hideLoader }) => {
      const [firstChat] = initialLead ? [initialLead] : list

      setChats(list)
      changeChat(firstChat, list, hideLoader)
    },
    [initialLead, changeChat]
  )

  const loadChat = useCallback(() => {
    services.whatsapp.leads({ business_id }).then(handleFetch)
  }, [business_id, handleFetch])

  useEffect(() => {
    const handle = network.setPolling({
      fn: loadChat,
      ms: 4000,
    })

    return () => {
      clearInterval(handle)
    }
  }, [loadChat])

  useEffect(() => {
    if (shouldReloadList) {
      loadChat()

      updateContext({ whatsapp: { shouldReloadList: false } })
    }
  }, [shouldReloadList, loadChat, updateContext])

  useEffect(() => {
    markAsRead()

    if (activeChat?.whatsapp_ticket_uuid) {
      services.whatsapp.notify({
        business_id,
        ticked_uuid: activeChat?.whatsapp_ticket_uuid,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChat, business_id, updateContext])

  return {
    chats,
    activeChat,
    changeChat,
    loadChat,
  }
}

export default useWhatsApp
