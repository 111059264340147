import { useParameter } from "hooks/useParameters"
import {
  createContext,
  useContext,
  useEffect,
  useCallback,
  useState,
} from "react"
import services from "services"
import scroll from "utils/scroll"
import _object from "utils/object"
import debounce from "utils/debounce"
import { Tabs, TabsName } from "./constants"

const PotentialCustomerContext = createContext()

const initialState = {
  leads: [],
  status: [],
  filters: Object(),
  search: String(),
  page: Tabs.Kanban,
  pagination: {
    quantity: 10,
  },
  modal: {
    lead_uuid: null,
    active: false,
  },
  redistribution: {
    active: false,
  },
  whatsapp: {
    lead: null,
    shouldReloadList: false,
  },
}

const PotentialCustomerProvider = ({ children, business }) => {
  const [context, setContext] = useState({ ...initialState })
  const [userUUID] = useParameter("user_uuid")
  const [page, setPage] = useParameter("page")

  const onCloseModal = () => {
    updateContext({
      modal: { active: false },
    })
    fetchLeads()
  }

  const openModal = (leadId) => {
    updateContext({
      modal: { lead_uuid: leadId, active: true },
    })
  }

  const openWhatsApp = (lead) => {
    updateContext({
      page: Tabs.WhatsApp,
      whatsapp: {
        lead,
      },
    })
  }

  const onCloseRedistribution = () => {
    updateContext({ redistribution: { active: false } })
    fetchLeads()
  }

  const updateContext = useCallback((state) => {
    setContext((prevState) => ({ ...prevState, ...state }))
  }, [])

  const onCleanContext = () => {
    setContext((prevState) => ({
      ...initialState,
      status: prevState.status,
      leads: prevState.leads,
    }))
  }

  const actOnLead = ({ allowed, lead, contactType, isTicketOpen }) => {
    if (contactType === "whatsapp" && isTicketOpen && allowed) {
      return () => {
        openWhatsApp(lead)
      }
    }

    return () => {
      openModal(lead?.lead_uuid)
    }
  }

  const handleFetch = useCallback(
    ({ kanban, status_count }) => {
      updateContext({
        leads: kanban,
        status: status_count,
      })
    },
    [updateContext]
  )

  const fetchLeads = useCallback(
    (props) => {
      services.bases
        .leads({
          business_id: business.business_id,
          user_uuid: userUUID,
          filters: context.filters,
          search: context.search,
          pagination: { page: props?.page },
        })
        .then(handleFetch)
    },
    [
      business.business_id,
      context.search,
      context.filters,
      userUUID,
      handleFetch,
    ]
  )

  const handleFetchInfinityPagination = ({ kanban }) => {
    const [data] = kanban

    setContext((prevState) => ({
      ...prevState,
      leads: prevState.leads.map((item, index) =>
        index === data.header.column - 1 ? data : item
      ),
      pagination: {
        quantity: prevState.pagination.quantity + 10,
      },
    }))
  }

  const onInfinityPagination = debounce((target, column) => {
    if (scroll.hasComeToEnd(target)) {
      services.bases
        .leads({
          business_id: business.business_id,
          user_uuid: userUUID,
          pagination: {
            column,
            quantity: context.pagination.quantity + 10,
          },
        })
        .then(handleFetchInfinityPagination)
    }
  }, 200)

  useEffect(() => {
    fetchLeads()
  }, [fetchLeads])

  useEffect(() => {
    if (context.page) {
      setPage(TabsName[context.page])
    }
  }, [context.page, setPage])

  useEffect(() => {
    const tab = Tabs[page]
    const isKanban = tab === Tabs["Kanban"]
    const areFiltersValid = !_object.isEmpty(context.filters)
    const hasToResetFilters = isKanban && areFiltersValid

    if (hasToResetFilters) {
      updateContext({
        filters: initialState.filters,
      })
    }

    if (tab) {
      updateContext({
        page: tab,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, updateContext])

  return (
    <PotentialCustomerContext.Provider
      value={{
        context: {
          ...context,
          modal: { ...context.modal, onCloseModal, openModal },
          redistribution: { ...context.redistribution, onCloseRedistribution },
          userUUID,
        },
        business,
        actOnLead,
        updateContext,
        onInfinityPagination,
        onCleanContext,
        refresh: fetchLeads,
      }}
    >
      {children}
    </PotentialCustomerContext.Provider>
  )
}

const usePotentialCustomerContext = () => useContext(PotentialCustomerContext)

export { PotentialCustomerProvider, usePotentialCustomerContext }
