import Panel from "components/Panel"
import FilterCode from "../Core"

import "./Aside.styl"

const Aside = ({ isActive, business, onClose, filters, setFilters }) => {
  return (
    <Panel className="filter-aside" isActive={isActive} onClose={onClose}>
      <FilterCode
        className="filter-aside__core"
        business={business}
        onClose={onClose}
        filters={filters}
        setFilters={setFilters}
      />
    </Panel>
  )
}
export default Aside
