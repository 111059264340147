import Card from "components/Card"
import LeadCard from "components/LeadCard"
import If from "components/If"
import FilterCore from "../Core/components/Filter/Core"
import EmptyState from "components/EmptyState"
import Pagination from "components/Pagination"
import Title from "components/Typography/Title"
import _pagination from "utils/pagination"
import useCan from "hooks/useCan"
import { usePotentialCustomerContext } from "../../context"

import { Desktop } from "components/Devices"

import "./SearchMode.styl"

const SearchMode = () => {
  const {
    context: { leads, search },
    business,
    refresh,
    actOnLead,
  } = usePotentialCustomerContext()
  const { allowed } = useCan({
    subject: [business.business_permission, "salesman"],
    action: ["whatsapp-management", "it"],
    and: true,
  })
  const [result] = leads

  return (
    <section className="search-mode">
      <Card className="search-mode__card" spacing="none">
        <Desktop>
          <aside className="search-mode__aside">
            <FilterCore business={business} className="search-mode__filter" />
          </aside>
        </Desktop>

        <div className="search-mode__content">
          <If condition={search.length}>
            <Title className="search-mode__title" variant="small">
              Resultado da busca para ”{search}”
            </Title>
          </If>
          <If
            condition={result?.paginated_result?.data?.length}
            renderIf={
              <>
                <ul className="search-mode__leads">
                  {Array.isArray(result?.paginated_result?.data) &&
                    result?.paginated_result?.data.map((lead) => (
                      <li className="kanban__lead" key={lead.lead_uuid}>
                        <LeadCard
                          statusColor={lead.status_color}
                          status={lead.lead_status}
                          name={lead.lead_name}
                          phone={lead.phone_number}
                          email={lead.email}
                          createdAt={lead.format_created_at}
                          updatedAt={lead.format_updated_at}
                          contactType={lead.contact_type}
                          onClick={actOnLead({
                            lead,
                            allowed,
                            contactType: lead.contact_type,
                            isTicketOpen: !lead.ticket_closure,
                          })}
                        />
                      </li>
                    ))}
                </ul>
                <Pagination
                  currentPage={
                    result?.paginated_result?.pagination?.current_page
                  }
                  pages={_pagination.getTotalPages(
                    result?.paginated_result?.pagination
                  )}
                  onChange={refresh}
                />
              </>
            }
            renderElse={
              <EmptyState
                className="search-mode__empty"
                text="Nenhum resultado encontrado"
              />
            }
          />
        </div>
      </Card>
    </section>
  )
}

export default SearchMode
