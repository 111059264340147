import { useState, useCallback, useEffect } from "react"
import services from "services"

const useFilter = ({ business = {}, setFilters }) => {
  const [filtersFields, setFiltersFields] = useState([])

  const handleFetch = useCallback((filters = {}) => {
    setFiltersFields(filters)
  }, [])

  const fetchFilters = useCallback(() => {
    services.bases
      .filters({ business_id: business?.business_id })
      .then(handleFetch)
  }, [handleFetch, business.business_id])

  const resetRemovedFields = ({ filters = {}, resetField }) => {
    // eslint-disable-next-line no-unused-expressions
    Object.entries(filters)
      ?.filter(([key, value]) => !value)
      ?.forEach(([key, value]) => {
        resetField(key, { defaultValue: " " })
      })
  }

  const onSubmit = (formData) => {
    const formattedFormData = Object.entries(formData)?.reduce(
      (acc, [key, value]) => {
        return {
          ...acc,
          [key]: value?.trim(),
        }
      },
      {}
    )
    setFilters(formattedFormData)
  }

  useEffect(() => {
    fetchFilters()
  }, [fetchFilters])

  return {
    onSubmit,
    filtersFields,
    resetRemovedFields,
  }
}

export default useFilter
