import Can from "components/Can"
import classnames from "classnames"
import useKanbanTabs from "./hooks"
import { Tabs } from "../../../../constants"

import "./KanbanTabs.styl"

const KanbanTabs = () => {
  const { tab, changeTab, business } = useKanbanTabs()

  return (
    <ul className="kanban-tabs" aria-label="Abas">
      <li
        className={classnames("kanban-tabs__item", {
          "kanban-tabs__item--active": [Tabs.Kanban, Tabs.Search].includes(tab),
        })}
        onClick={() => changeTab(Tabs.Kanban)}
      >
        Principal
      </li>
      <Can
        subject={[business.business_permission, "salesman"]}
        and={true}
        do={["whatsapp-management", "it"]}
      >
        <li
          className={classnames("kanban-tabs__item", {
            "kanban-tabs__item--active": tab === Tabs.WhatsApp,
          })}
          onClick={() => changeTab(Tabs.WhatsApp)}
        >
          WhatsApp
        </li>
      </Can>
    </ul>
  )
}

export default KanbanTabs
