import If from "components/If"
import Kanban from "../Kanban"
import SearchMode from "../SearchMode"
import WhatsApp from "../WhatsApp"
import { usePotentialCustomerContext } from "../../context"
import { Tabs } from "../../constants"

const Content = () => {
  const {
    context: { page },
  } = usePotentialCustomerContext()

  return (
    <>
      <If condition={page === Tabs.Kanban}>
        <Kanban />
      </If>
      <If condition={page === Tabs.Search}>
        <SearchMode />
      </If>
      <If condition={page === Tabs.WhatsApp}>
        <WhatsApp />
      </If>
    </>
  )
}

export default Content
