import Card from "components/Card"
import EmptyState from "components/EmptyState"
import Hint from "components/Hint"
import If from "components/If"
import LeadCard from "components/LeadCard"
import useCan from "hooks/useCan"
import { usePotentialCustomerContext } from "../../context"

import "./Kanban.styl"

const Kanban = () => {
  const {
    context: { leads },
    business,
    actOnLead,
    onInfinityPagination,
  } = usePotentialCustomerContext()
  const { allowed } = useCan({
    subject: [business.business_permission, "salesman"],
    action: ["whatsapp-management", "it"],
    and: true,
  })

  return (
    <ul className="kanban">
      {leads?.map((column) => (
        <li
          data-test="kanban-item"
          className="kanban__item"
          key={column.header.column}
        >
          <Card
            className="kanban__card"
            spacing="none"
            title={column.header.title}
            subtitle={`${column.paginated_result.pagination.total_records} Leads`}
          >
            <ul
              className="kanban__leads"
              onScroll={({ target }) =>
                onInfinityPagination(target, column.header.column)
              }
            >
              <If
                condition={column.paginated_result.data.length}
                renderIf={column.paginated_result.data.map((lead) => (
                  <li className="kanban__lead" key={lead.lead_uuid}>
                    <LeadCard
                      statusColor={lead.status_color}
                      status={lead.lead_status}
                      name={lead.lead_name}
                      phone={lead.phone_number}
                      email={lead.email}
                      createdAt={lead.format_created_at}
                      updatedAt={lead.format_updated_at}
                      contactType={lead.contact_type}
                      onClick={actOnLead({
                        lead,
                        allowed,
                        contactType: lead.contact_type,
                        isTicketOpen: !lead.ticket_closure,
                      })}
                    />
                  </li>
                ))}
                renderElse={<EmptyState className="kanban__empty" />}
              />
            </ul>
            <div className="kanban__hint-wrapper">
              <Hint
                horizontal="right"
                hasAutoWidth={true}
                className="kanban__hint"
              >
                <ul className="kanban__hint-list">
                  {column.header?.status_counter_by_column_by_id?.map(
                    (status) => (
                      <li className="kanban__hint-item" key={status.status_id}>
                        {status.status_name} - <b>{status.count}</b>
                      </li>
                    )
                  )}
                </ul>
              </Hint>
            </div>
          </Card>
        </li>
      ))}
    </ul>
  )
}

export default Kanban
