import Card from "components/Card"
import Text from "components/Typography/Text"
import ChatCard from "../ChatCard"

import "./ChatList.styl"

const ChatList = ({ activeLeadId, chats, changeChat }) => (
  <Card className="chat-list" spacing="none">
    <section className="chat-list__header">
      <Text className="chat-list__title">Conversas</Text>
      <Text className="chat-list__number-of-leads">{chats.length} leads</Text>
    </section>
    <ul className="chat-list__cards" aria-label="Lista de conversas">
      {chats.map((chat, index) => (
        <li className="chat-list__card" key={index}>
          <ChatCard
            active={activeLeadId === chat?.lead_uuid}
            unread={chat?.unread}
            status={chat?.status_name}
            statusColor={chat?.status_color}
            name={chat?.lead_name}
            phone={chat?.phone_number}
            hour={chat?.hour}
            onClick={() => changeChat(chat)}
          />
        </li>
      ))}
    </ul>
  </Card>
)

export default ChatList
